import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { GeneratorComponent } from './generator/generator.component';
import { QuotationListComponent } from './generator/quotation-list/quotation-list.component';
import { SearcherComponent } from './searcher/searcher.component';
import { ResultListComponent } from './searcher/result-list/result-list.component';
import { SearchPanelComponent } from './searcher/search-panel/search-panel.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { CustomerDetailComponent } from './generator/customer-detail/customer-detail.component';
import { QuotationAutoGenerateComponent } from './generator/quotation-auto-generate/quotation-auto-generate.component';
import { GeneratorService } from './generator/generator.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { QuotationSummaryComponent } from './generator/quotation-summary/quotation-summary.component';
import { PreviewQuotationComponent } from './generator/preview-quotation/preview-quotation.component';
import { ApiCallService } from './api-call.service';
import { ImageUploadService } from './image-upload.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoadingInterceptor } from './loading.interceptor';
import {
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import {
  HashLocationStrategy,
  LocationStrategy,
  CurrencyPipe,
} from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
@NgModule({
  declarations: [
    AppComponent,
    GeneratorComponent,
    QuotationListComponent,
    SearcherComponent,
    ResultListComponent,
    SearchPanelComponent,
    CustomerDetailComponent,
    QuotationAutoGenerateComponent,
    QuotationSummaryComponent,
    PreviewQuotationComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatFormFieldModule,
    MatInputModule,
  ],
  providers: [
    GeneratorService,
    HttpClient,
    CurrencyPipe,
    ImageUploadService,
    ApiCallService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingInterceptor,
      multi: true,
    },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
