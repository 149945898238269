<div class="bg-dark2 search-panel">
  <button
    type="button"
    class="btn btn-primary m-2"
    (click)="isCollapsed = !isCollapsed"
    [attr.aria-expanded]="!isCollapsed"
    aria-controls="collapseExample"
  >
    ย่อขยายตัวกรองข้อมูลสินค้า
  </button>
  <button
    type="button"
    class="btn btn-danger m-2"
    (click)="generatorService.resetItemSearch()"
  >
    ล้างตัวกรองข้อมูล
  </button>
  <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
    <form [formGroup]="itemSearchForm">
      <div class="bg-dark2 p-4">
        <div class="row">
          <div class="col-sm-4">
            <label for="type" class="form-label">service</label>
            <div class="input-group mb-3">
              <select formControlName="service" class="form-select" id="type">
                <option value=""></option>
                <option
                  *ngFor="let choice of itemChoice.service"
                  [value]="choice.ServiceId"
                >
                  {{ choice.ServiceName }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-sm-8">
            <label for="type" class="form-label">ประเภทงาน</label>
            <div class="input-group mb-3">
              <select formControlName="type" class="form-select" id="type">
                <option value=""></option>
                <option
                  *ngFor="let choice of itemChoice.workType"
                  [value]="choice.WorkTypeId"
                >
                  {{ choice.WorkTypeName }}
                </option>
              </select>
            </div>
          </div>
          <div class="row"></div>
          <div class="col-sm-8">
            <label for="cutter" class="form-label">มีดตัดเฟือง</label>

            <div class="input-group mb-3">
              <select
                formControlName="cutter"
                class="form-select form-select-sm"
                id="cutter"
              >
                <option value=""></option>
                <option
                  *ngFor="let choice of itemChoice.cutter"
                  [value]="choice.CutterId"
                >
                  {{ choice.CutterName }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-sm-4">
            <label for="cutterNo" class="form-label">เบอร์มีด</label>

            <div class="input-group mb-3">
              <input
                id="cutterNo"
                formControlName="cutterNo"
                type="text"
                class="form-control"
                aria-label="cutterNo"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <label for="coating" class="form-label">ชุบ (Heat treatment)</label>

            <div class="input-group mb-3">
              <select
                formControlName="coating"
                class="form-select form-select-sm"
                id="coating"
              >
                <option value=""></option>
                <option
                  *ngFor="let choice of itemChoice.coating"
                  [value]="choice.CoatingId"
                >
                  {{ choice.CoatingName }}
                </option>
              </select>
            </div>
          </div>

          <div class="col-sm-12">
            <label for="polishing" class="form-label">การเจียร</label>
            <div class="input-group mb-3" style="color: white">
              <div
                class="m-1 form-check"
                *ngFor="let choice of itemChoice.polishing"
              >
                <input
                  style="color: white"
                  type="checkbox"
                  (click)="addPolishing()"
                  [value]="choice.PolishingId"
                  class="form-check-input"
                  [id]="'polishing-' + choice.PolishingId"
                />
                <label class="form-check-label">
                  {{ choice.PolishingName }}
                </label>
              </div>

              <!-- <select
                formControlName="polishing"
                class="form-select form-select-sm"
                id="polishing"
              >
                <option value=""></option>
                <option
                  *ngFor="let choice of itemChoice.polishing"
                  [value]="choice.PolishingId"
                >
                  {{ choice.PolishingName }}
                </option>
              </select> -->
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6">
            <label for="material" class="form-label">วัสดุ</label>

            <div class="input-group mb-3">
              <select
                formControlName="material"
                class="form-select form-select-sm"
                id="material"
              >
                <option value=""></option>
                <option
                  *ngFor="let choice of itemChoice.material"
                  [value]="choice.MaterialId"
                >
                  {{ choice.MaterialName }}
                </option>
              </select>
            </div>
          </div>

          <div class="col-sm-6">
            <label for="matCountry" class="form-label">จากประเทศ</label>
            <div class="input-group mb-3">
              <select
                formControlName="matCountry"
                class="form-select form-select-sm"
                id="matCountry"
              >
                <option value=""></option>
                <option
                  *ngFor="let choice of itemChoice.country"
                  [value]="choice.CountryId"
                >
                  {{ choice.CountryName }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <label for="gearNumberMin" class="form-label">จำนวนฟัน</label>

            <div class="input-group mb-3">
              <input
                formControlName="gearNumberMin"
                placeholder="ต่ำสุด"
                type="number"
                class="form-control"
                aria-label="gearNumberMin"
              />
              <input
                formControlName="gearNumberMax"
                placeholder="สูงสุด"
                type="number"
                class="form-control"
                aria-label="gearNumberMax"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <label for="odMin" class="form-label">OD</label>

            <div class="input-group mb-3">
              <input
                id="odMin"
                formControlName="odMin"
                type="number"
                placeholder="ต่ำสุด"
                class="form-control"
                aria-label="OD"
                step="0.01"
              />
              <input
                id="odMax"
                formControlName="odMax"
                placeholder="สูงสุด"
                type="number"
                class="form-control"
                aria-label="OD"
                step="0.01"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <label for="length" class="form-label">ความยาวเฟือง</label>
            <div class="input-group mb-3">
              <input
                id="length"
                placeholder="ต่ำสุด"
                formControlName="lengthMin"
                type="number"
                class="form-control"
                aria-label="lengthMin"
                step="0.01"
              />
              <input
                id="lengthMax"
                placeholder="สูงสุด"
                formControlName="lengthMax"
                type="number"
                class="form-control"
                aria-label="lengthMax"
                step="0.01"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <label for="pricePerPieceMin" class="form-label">ราคาต่อชิ้น</label>
            <div class="input-group mb-3">
              <input
                id="pricePerPieceMin"
                placeholder="ต่ำสุด"
                formControlName="pricePerPieceMin"
                type="number"
                class="form-control"
                aria-label="pricePerPieceMin"
                step="0.01"
              />
              <input
                id="pricePerPieceMax"
                placeholder="สูงสุด"
                formControlName="pricePerPieceMax"
                type="number"
                class="form-control"
                aria-label="pricePerPieceMax"
                step="0.01"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <label for="clientName" class="form-label">ชื่อลูกค้า</label>
            <div class="input-group mb-3">
              <span class="input-group-text">ชื่อลูกค้า</span>
              <input
                id="clientName"
                formControlName="clientName"
                type="text"
                class="form-control"
                aria-label="clientName"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <label for="quotationNumber" class="form-label"
              >เลขที่ใบเสนอราคา</label
            >
            <div class="input-group mb-3">
              <span class="input-group-text">เลขที่ใบเสนอราคา</span>
              <input
                id="quotationNumber"
                formControlName="quotationNumber"
                type="text"
                class="form-control"
                aria-label="quotationNumber"
              />
            </div>
          </div>
        </div>
      </div>
    </form>
    <button class="btn btn-success mb-3 mx-3" (click)="searchItem()">
      ค้นหา
    </button>
  </div>
</div>
