<form [formGroup]="quotationInfo" class="was-validated">
  <div formArrayName="quotationList">
    <div *ngFor="let control of ItemList.controls; let index = index">
      <form [formGroupName]="index">
        <div class="row">สินค้า: {{ index + 1 }}</div>

        <div class="row">
          <div class="col-sm-3">
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <label class="input-group-text" for="type">Service</label>
              </div>
              <select
                formControlName="service"
                class="form-select form-select-sm"
                id="type"
                (change)="addChoice('service', 'Service', $event, index)"
                required
              >
                <option value="0"></option>
                <option
                  *ngFor="let choice of itemChoice.service"
                  [value]="choice.ServiceId"
                >
                  {{ choice.ServiceName }}
                </option>
                <option value="new">อื่นๆ</option>
              </select>
            </div>
          </div>
          <div class="col-sm-3">
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <label class="input-group-text" for="type">ประเภทงาน</label>
              </div>
              <select
                formControlName="type"
                class="form-select form-select-sm"
                id="type"
                (change)="addChoice('type', 'WorkType', $event, index)"
                required
              >
                <option value="0"></option>
                <option
                  *ngFor="let choice of itemChoice.workType"
                  [value]="choice.WorkTypeId"
                >
                  {{ choice.WorkTypeName }}
                </option>
                <option value="new">อื่นๆ</option>
              </select>
            </div>
          </div>
          <div class="col-sm-3">
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <label class="input-group-text" for="cutter">มีดตัดเฟือง</label>
              </div>
              <select
                formControlName="cutter"
                class="form-select form-select-sm"
                id="cutter"
                (change)="addChoice('cutter', 'Cutter', $event, index)"
                required
              >
                <option value="0"></option>
                <option
                  *ngFor="let choice of itemChoice.cutter"
                  [value]="choice.CutterId"
                >
                  {{ choice.CutterName }}
                </option>
                <option value="new">อื่นๆ</option>
              </select>
              <button class="btn btn-danger" (click)="ItemList.at(index).get('cutter')?.setValue('0')">
                ไม่ใช้
              </button>
            </div>
          </div>
          <div class="col-sm-3">
            <div class="input-group mb-3">
              <label class="input-group-text" for="cutterNo">เบอร์มีด</label>
              <input
                formControlName="cutterNo"
                type="text"
                class="form-control"
                aria-label="cutterNo"
              />
              <button class="btn btn-danger" (click)="ItemList.at(index).get('cutterNo')?.setValue('')">
                ไม่ใช้
              </button>
            </div>
          </div>
          <div class="col-sm-3">
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <label class="input-group-text" for="coating"
                  >ชุบ (Heat treatment)</label
                >
              </div>
              <select
                formControlName="coating"
                class="form-select form-select-sm"
                id="coating"
                (change)="addChoice('coating', 'Coating', $event, index)"
                required
              >
                <option value="0"></option>
                <option
                  *ngFor="let choice of itemChoice.coating"
                  [value]="choice.CoatingId"
                >
                  {{ choice.CoatingName }}
                </option>
                <option value="new">อื่นๆ</option>
              </select>
            </div>
          </div>
          <div class="col-sm-3">
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <label class="input-group-text" for="dropdown">การเจียร</label>
              </div>
              <!-- {{ItemList.at(index).get('polishing')?.getRawValue()}} -->
              <div
                class="m-1 form-check"
                *ngFor="let choice of itemChoice.polishing"
              >
                <input
                  type="checkbox"
                  (click)="addPolishing(index, choice.PolishingId)"
                  [value]="choice.PolishingId"
                  class="form-check-input"
                  [checked]="
                    ItemList.at(index)
                      .get('polishing')
                      ?.value?.includes(addDoubleQuote(choice.PolishingName))
                  "
                  [id]="'polishing-' + index + '-' + choice.PolishingId"
                />
                <label class="form-check-label">
                  {{ choice.PolishingName }}
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-3">
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <label class="input-group-text" for="dropdown">วัสดุ</label>
              </div>
              <select
                formControlName="material"
                class="form-select form-select-sm"
                id="material"
                (change)="addChoice('material', 'Material', $event, index)"
                required
              >
                <option value="0"></option>
                <option
                  *ngFor="let choice of itemChoice.material"
                  [value]="choice.MaterialId"
                >
                  {{ choice.MaterialName }}
                </option>
                <option value="new">อื่นๆ</option>
              </select>
            </div>
          </div>
          <div class="col-sm-3">
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <label class="input-group-text" for="dropdown">จากประเทศ</label>
              </div>
              <select
                formControlName="matCountry"
                class="form-select form-select-sm"
                id="matCountry"
                (change)="addChoice('matCountry', 'Country', $event, index)"
              >
                <!-- <option value="0"></option> -->
                <option value="0">ไม่ระบุประเทศ</option>
                <option
                  *ngFor="let choice of itemChoice.country?.slice(1)"
                  [value]="choice.CountryId"
                >
                  {{ choice.CountryName }}
                </option>
                <option value="new">อื่นๆ</option>
              </select>
            </div>
          </div>
          <div class="col-sm-3">
            <div class="input-group mb-3">
              <span class="input-group-text">OD</span>
              <input
                formControlName="od"
                type="number"
                class="form-control"
                aria-label="OD"
                step="0.01"
              />
            </div>
          </div>
          <div class="col-sm-3">
            <div class="input-group mb-3">
              <span class="input-group-text">ความยาวเฟือง</span>
              <input
                formControlName="length"
                type="number"
                class="form-control"
                aria-label="OD"
                step="0.01"
              />
            </div>
          </div>
          <div class="col-sm-3">
            <div class="input-group mb-3">
              <span class="input-group-text">จำนวนฟัน</span>
              <input
                formControlName="gearNumber"
                type="number"
                class="form-control"
                aria-label="gearNumber"
                required
              />
            </div>
          </div>
        </div>
        <div
          class="row"
          *ngIf="ItemList.at(index).get('itemSet')?.value != 'multi'"
        >
          <div class="col-sm-3">
            <div class="input-group mb-3">
              <span class="input-group-text">ราคาต่อชิ้น</span>
              <input
                (focusout)="reCalculateTotal(index)"
                formControlName="pricePerPiece"
                type="number"
                class="form-control"
                aria-label="pricePerPiece"
                required
              />
            </div>
          </div>
          <div class="col-sm-3">
            <div class="input-group mb-3">
              <span class="input-group-text">จำนวนชิ้น</span>
              <input
                (focusout)="reCalculateTotal(index)"
                formControlName="qty"
                type="number"
                class="form-control"
                aria-label="qty"
                required
              />
            </div>
          </div>

          <div class="col-sm-3">
            <div class="input-group mb-3">
              <span class="input-group-text">ส่วนลด</span>
              <input
                *ngIf="!discountSelector[index]"
                (focusout)="reCalculateTotal(index)"
                formControlName="discount"
                type="number"
                placeholder="%"
                class="form-control"
                aria-label="discount"
                required
              />
              <input
                *ngIf="discountSelector[index]"
                (focusout)="reCalculateTotal(index)"
                formControlName="discountManual"
                type="number"
                placeholder="baht"
                class="form-control"
                aria-label="discountManual"
                required
              />
              <button
                *ngIf="!discountSelector[index]"
                (click)="
                  changeDiscountType(index, true); reCalculateTotal(index)
                "
                class="btn btn-danger"
              >
                %
              </button>
              <button
                *ngIf="discountSelector[index]"
                (click)="
                  changeDiscountType(index, null); reCalculateTotal(index)
                "
                class="btn btn-danger"
              >
                บาท
              </button>
            </div>
          </div>
          <div class="col-sm-3">
            <div class="input-group mb-3">
              <span class="input-group-text">ราคารวม</span>
              <input
                formControlName="amount"
                type="number"
                step="0.01"
                class="form-control"
                aria-label="amount"
                required
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6">
            <div class="input-group mb-3">
              <span class="input-group-text">รายละเอียดเพิ่มเติม</span>
              <input
                formControlName="addInfo"
                type="text"
                class="form-control"
                aria-label="addInfo"
              />
            </div>
          </div>
          <div class="col-sm-3">
            <div class="input-group mb-3">
              <span class="input-group-text">หมายเหตุ</span>
              <input
                formControlName="remark"
                type="text"
                class="form-control"
                aria-label="remark"
              />
            </div>
          </div>
          <div class="col-sm-3">
            <div class="input-group mb-3">
              <span class="input-group-text">งานชุด/เดี่ยว</span>
              <select
                class="form-select form-select-sm"
                formControlName="itemSet"
                (change)="setPrice(index)"
              >
                <option value=""></option>
                <option value="single">งานเดี่ยว</option>
                <option value="multi">งานชุด</option>
                <option value="multiFinal">งานชุด(สรุปราคา)</option>
              </select>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6">
            <div class="input-group m-1">
              <label class="input-group-text" [for]="'inputGroupFile' + index"
                >เลือกรูปภาพ</label
              >
              <input
                type="file"
                accept="image/*"
                class="form-control"
                (change)="selectFile($event, index)"
                [id]="'inputGroupFile' + index"
              />
            </div>
          </div>
          <div class="col-sm-3">
            <div>
              <img
                class="attach-img"
                [src]="ItemList.at(index).get('imgUrl')?.value"
                (error)="handleMissingImage($event, index)"
                *ngIf="
                  (itemImageTempList[index] ||
                    ItemList.at(index).get('imgUrl')?.value) &&
                  ItemList.at(index).get('imgUrl')?.value != 'Not found'
                "
              />
            </div>
          </div>
          <div class="col-sm-3">
            <button class="btn btn-danger m-1" (click)="deleteItemList(index)">
              ลบสินค้า
            </button>
          </div>
        </div>
      </form>
    </div>
    <button class="btn btn-success m-1" (click)="addItemList()">
      เพิ่มสินค้า
    </button>
  </div>
</form>
