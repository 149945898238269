import { Component, Input, OnInit } from '@angular/core';
import { workType, cutter, polishing, coating } from '../../model/model';
import { GeneratorService } from '../../generator/generator.service';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiCallService } from '../../api-call.service';

@Component({
  selector: 'app-result-list',
  templateUrl: './result-list.component.html',
  styleUrls: ['./result-list.component.scss'],
})
export class ResultListComponent implements OnInit {
  @Input() item: any;

  public quotationForm: FormGroup;
  public itemList: any[];

  public quotation: any;
  public workType = workType;
  public cutter = cutter;
  public polishing = polishing;
  public coating = coating;

  public isCollapsed = true;
  public isRevCollapsed = false;
  public itemChoice: any;

  constructor(
    private generatorService: GeneratorService,
    private http: ApiCallService
  ) {}

  ngOnInit(): void {
    this.generatorService.onItemChoiceChange().subscribe((itemChoice) => {
      this.itemChoice = itemChoice;
    });
  }

  public showQuotation(quotationNo: number) {
    window.open('/#/preview;quotationNo=' + quotationNo, '_blank');
  }

  public showPicture(url: string, id: number): void {
    console.log('img-' + id + ': ' + url);
    (document.getElementById('img-' + id) as HTMLImageElement).src = url;
    this.isCollapsed = !this.isCollapsed;
  }

  public getItemList() {
  this.isRevCollapsed = !this.isRevCollapsed;

    if (this.item.QuotationNumber && (!this.itemList)) {
      this.http
        .get('/api/quotations/itemSoldHistory', {
          withCredentials: false,
        })
        .subscribe((data) => {
          this.itemList = data;
        });
    }
  }
}
