import { Component, OnInit } from '@angular/core';
import { ApiCallService } from './api-call.service';
import { GeneratorService } from './generator/generator.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'quotationGenerator';

  constructor(
    private http : ApiCallService,
    private generatorService : GeneratorService
  ){}

  ngOnInit(): void {
    this.http.get('api/quotations/itemChoice').subscribe((itemChoice)=>{
      this.generatorService.setItemChoice(itemChoice);
    })
  }
}
