import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
@Injectable()
export class ImageUploadService {
  constructor() {}

  public uuidv4(): number {
    return Math.floor(new Date().getTime());
  }

  private imgLink: BehaviorSubject<string> = new BehaviorSubject('');

  public uploadImage(imageFile: any): Observable<string> {
    let postid = this.uuidv4();
    let file = imageFile;
    // Create new file so we can rename the file
    let blob = file.slice(0, file.size, 'image/jpeg');
    let newFile = new File([blob], `${postid}_post.jpeg`, {
      type: 'image/jpeg',
    });
    // Build the form data - You can add other input values to this i.e descriptions, make sure img is appended last
    let formData = new FormData();
    formData.append('imgfile', newFile);
    fetch('/upload', {
      method: 'POST',
      body: formData,
    }).then((res) => {
      // res.text();
      switch (res.status) {
        case 200:
          this.imgLink.next(
            `https://storage.googleapis.com/gothic-style-379913.appspot.com/${postid}_post.jpeg`
          );
          return this.imgLink.asObservable();
        case 416:
          alert('ไฟล์มีขนาดใหญ่เกินไป');
          this.imgLink.next('Not found');
          return this.imgLink.asObservable();
        default:
          alert('พบปัญหาขณะอัพโหลดไฟล์ภาพ');
          this.imgLink.next('Not found');
          return this.imgLink.asObservable();
      }

      //   return `https://storage.cloud.google.com/gothic-style-379913.appspot.com/${postid}_post.jpeg`;
    });
    this.imgLink.next(
      `https://storage.googleapis.com/gothic-style-379913.appspot.com/${postid}_post.jpeg`
    );
    return this.imgLink.asObservable();
  }
}
