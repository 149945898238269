import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { GeneratorService } from './generator.service';
import { Router } from '@angular/router';
import { ApiCallService } from '../api-call.service';
@Component({
  selector: 'app-generator',
  templateUrl: './generator.component.html',
  styleUrls: ['./generator.component.scss'],
})
export class GeneratorComponent implements OnInit {
  public quotationForm: FormGroup;
  public itemChoice: {};

  constructor(
    private generatorService: GeneratorService,
    private router: Router,
    private http: ApiCallService
  ) {}

  ngOnInit(): void {
    this.generatorService.onQuotationChange().subscribe((quotation) => {
      this.quotationForm = quotation;
      // console.log(this.quotationForm);
    });
  }

  public previewQuotation() {
    this.generatorService.setQuotation(this.quotationForm);
    this.router.navigate(['preview']);
  }

  public convertToItemSqlList(itemList: Array<any>) {
    let itemListSql = '';
    itemList.forEach((item) => {
      itemListSql =
        itemListSql +
        '(' +
        item.type +
        ', ' +
        item.service +
        ', ' +
        item.cutter +
        ", '[" +
        item.polishing +
        "]', " +
        item.coating +
        ', ' +
        item.gearNumber +
        ", '" +
        item.od +
        "', '" +
        item.length +
        "', " +
        item.material +
        ', ' +
        item.matCountry +
        ", '" +
        item.remark +
        "', '" +
        item.imgUrl +
        "', '" +
        item.addInfo +
        "', '" +
        item.cutterNo +
        "'),";
    });
    return itemListSql.slice(0, -1);
  }

  public convertToItemSoldHistorySqlList(
    itemSoldHistoryList: Array<any>,
    itemId: number,
    quotationId: number
  ) {
    let itemListSql = '';
    itemSoldHistoryList.forEach((item, index) => {
      itemListSql =
        itemListSql +
        '(' +
        (itemId + index) +
        ', ' +
        item.qty +
        ', ' +
        item.amount +
        ', "' +
        item.itemSet +
        '", ' +
        quotationId +
        ', '+
        item.discount+
        '),';
    });
    return itemListSql.slice(0, -1);
  }

  public uploadQuotation() {
    this.generatorService.setQuotation(this.quotationForm);

    if (!this.quotationForm.valid) {
      alert('กรุณากรอกข้อมูลให้ครบ');
      console.log(this.quotationForm.getRawValue());

      return;
    }
    let date = new Date();
    let dateString =
      date.getUTCFullYear() +
      '-' +
      ('00' + (date.getUTCMonth() + 1)).slice(-2) +
      '-' +
      ('00' + date.getUTCDate()).slice(-2);

    let clientBody = {
      ClientName: this.quotationForm.get('customerDetail')?.get('clientName')
        ?.value,
      Address: this.quotationForm.get('customerDetail')?.get('address')?.value,
      Contact: this.quotationForm.get('customerDetail')?.get('contactName')
        ?.value,
      Telephone: this.quotationForm.get('customerDetail')?.get('telephone')
        ?.value,
      Email: this.quotationForm.get('customerDetail')?.get('email')?.value,
    };

    let body = {
      QuotationNumber: this.quotationForm
        .get('quotationAutoGen')
        ?.get('quotationNumber')?.value,
      ClientId: 1,
      Total: this.quotationForm.get('quotationSummary')?.get('total')?.value,
      GTotal: this.quotationForm.get('quotationSummary')?.get('grandTotal')
        ?.value,
      Deposit: this.quotationForm.get('quotationSummary')?.get('deposit')
        ?.value,
      Remark: this.quotationForm.get('quotationSummary')?.get('remark')?.value,
      quotationDate: dateString,
      CreditTerm: this.quotationForm.get('quotationAutoGen')?.get('creditTerm')
        ?.value,
      FinalDiscount: this.quotationForm.get('quotationSummary')?.get('discount')
        ?.value,
      Delivery: this.quotationForm.get('quotationAutoGen')?.get('delivery')
        ?.value,
      approver: this.quotationForm.get('quotationSummary')?.get('approver')
        ?.value,
      Creator: this.quotationForm.get('quotationSummary')?.get('creator')
        ?.value,
      SignedBy: this.quotationForm.get('quotationSummary')?.get('signedBy')
        ?.value,
      itemList: this.convertToItemSqlList(
        this.quotationForm.getRawValue().quotationList
      ),
    };
    this.http
      .post(
        '/api/quotations/client',
        { withCredentials: false },
        { filter: clientBody }
      )
      .subscribe((clientData) => {
        if (clientData.ClientId != undefined) {
          body.ClientId = clientData.ClientId;
        } else {
          body.ClientId = clientData[0].ClientId;
        }
        this.http
          .post('/api/quotations', { withCredentials: false }, body)
          .subscribe((quotationData) => {
            this.http
              .post('/api/quotations/item', { withCredentials: false }, body)
              .subscribe((itemData) => {
                let body2 = {
                  itemSoldHistory: this.convertToItemSoldHistorySqlList(
                    this.quotationForm.getRawValue().quotationList,
                    itemData.id,
                    quotationData.id
                  ),
                };
                this.http
                  .post(
                    '/api/quotations/itemSoldHistory',
                    { withCredentials: false },
                    body2
                  )
                  .subscribe((data) => {
                    alert('บันทึกข้อมูลสำเร็จ')
                    this.router.navigate(['preview']);
                  });
              });
          });
      });
  }
}
