import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-quotation-auto-generate',
  templateUrl: './quotation-auto-generate.component.html',
  styleUrls: ['./quotation-auto-generate.component.scss']
})
export class QuotationAutoGenerateComponent implements OnInit {
  @Input() quotationInfo: FormGroup;

  constructor() { }

  ngOnInit(): void {
  }

  public setDeposit(event:any):void{
    if(event.target.value === 'มัดจำ 50% เมื่อตกลง / ส่วนที่เหลือในวันรับของ'){
      this.quotationInfo.get('quotationSummary')?.get('deposit')?.setValue(50)
    }
  }
}
