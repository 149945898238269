import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { QuotationForm } from 'src/app/model/model';
import { GeneratorService } from '../generator.service';
import { ImageUploadService } from 'src/app/image-upload.service';
import { ApiCallService } from 'src/app/api-call.service';
import { take } from 'rxjs';
@Component({
  selector: 'app-quotation-list',
  templateUrl: './quotation-list.component.html',
  styleUrls: ['./quotation-list.component.scss'],
})
export class QuotationListComponent implements OnInit {
  @Input() quotationInfo: FormGroup;
  public msg = '';
  public url: any; //Angular 11, for stricter type
  public itemImageTempList: any[] = [];
  public discountSelector: any[] = [];
  public itemChoice: any;

  constructor(
    private generatorService: GeneratorService,
    private imageUploadService: ImageUploadService,
    private http: ApiCallService
  ) {}

  ngOnInit(): void {
    this.generatorService.onItemChoiceChange().subscribe((itemChoice) => {
      this.itemChoice = itemChoice;
      setTimeout(
        () => this.quotationInfo.setValue(this.quotationInfo.getRawValue()),
        100
      );
    });
    this.ItemList.getRawValue().forEach((item: any, index: number) => {
      this.reCalculateTotal(index);
    });
  }
  get ItemList() {
    return this.quotationInfo.get('quotationList') as FormArray;
  }

  public addPolishing(index: number, polishingId: number): void {
    // this.ItemList.at(index).get('polishing')?.setValue([]);
    let tempPolishArr: any[] = [];
    this.itemChoice.polishing.forEach(
      (element: { PolishingId: number; PolishingName: any }) => {
        let cb = <HTMLInputElement>(
          document.getElementById(
            'polishing-' + index + '-' + element.PolishingId
          )
        );
        if (cb?.checked) {
          tempPolishArr.push('"' + element.PolishingName + '"');
        }
      }
    );
    this.ItemList.at(index).get('polishing')?.setValue(tempPolishArr);
  }

  public addDoubleQuote(text: string): string {
    return '"' + text + '"';
  }

  public selectFile(event: any, index: number) {
    //Angular 11, for stricter type
    if (!event.target.files[0] || event.target.files[0].length == 0) {
      this.msg = 'You must select an image';
      console.log(this.msg);
      return;
    }

    var mimeType = event.target.files[0].type;

    if (mimeType.match(/image\/*/) == null) {
      this.msg = 'Only images are supported';
      console.log(this.msg);
      return;
    }

    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);

    reader.onload = (_event) => {
      const ItemList = this.quotationInfo.get('quotationList') as FormArray;
      this.imageUploadService
        .uploadImage(event.target.files[0])
        .pipe(take(1))
        .subscribe((imgResponse) => {
          console.log(ItemList);
          ItemList.at(index).get('imgUrl')?.setValue(imgResponse);
          this.itemImageTempList[index] = reader.result;
        });
    };
  }

  public handleMissingImage(event: any, index: number): void {
    (event.target as HTMLImageElement).src = this.itemImageTempList[index];
  }

  public addItemList(): void {
    const ItemList = this.quotationInfo.get('quotationList') as FormArray;
    ItemList.push(this.generatorService.getQuotationItem());
  }

  public deleteItemList(index: number): void {
    const ItemList = this.quotationInfo.get('quotationList') as FormArray;
    ItemList.removeAt(index);
  }

  public reCalculateTotal(index: number) {
    let ItemList = this.quotationInfo.get('quotationList') as FormArray;
    let percentDiscount = ItemList?.at(index)?.get('discount')?.value;
    let manualDiscount = ItemList?.at(index)?.get('discountManual')?.value;
    console.log(ItemList.getRawValue());

    if (this.discountSelector[index]) {
      percentDiscount =
        (ItemList?.at(index)?.get('discountManual')?.value /
          ItemList?.at(index)?.get('pricePerPiece')?.value) *
        100;

      ItemList?.at(index)?.get('discount')?.setValue(percentDiscount);
    } else {
      manualDiscount =
        (ItemList?.at(index)?.get('discount')?.value *
          ItemList?.at(index)?.get('pricePerPiece')?.value) /
        100;
      ItemList?.at(index)?.get('discountManual')?.setValue(manualDiscount);
      percentDiscount = ItemList?.at(index)?.get('discount')?.value;
    }

    ItemList?.at(index)
      ?.get('amount')
      ?.setValue(
        (
          (this.quotationInfo.get('quotationList')?.value[index].qty *
            this.quotationInfo.get('quotationList')?.value[index]
              .pricePerPiece *
            (100 - percentDiscount)) /
          100
        ).toFixed(2)
      );
    ItemList?.at(index)?.get('amount')?.markAsTouched();

    let tempTotal: number = this.quotationInfo
      .get('quotationList')
      ?.value.reduce(
        (acc: any, cur: any) => parseFloat(acc) + parseFloat(cur.amount),
        0
      );
    tempTotal -= this.quotationInfo
      .get('quotationSummary')
      ?.get('discount')?.value;
    this.quotationInfo
      .get('quotationSummary')
      ?.get('total')
      ?.setValue(tempTotal.toFixed(2));
    this.quotationInfo
      .get('quotationSummary')
      ?.get('vat')
      ?.setValue((tempTotal * 0.07).toFixed(2));
    this.quotationInfo
      .get('quotationSummary')
      ?.get('grandTotal')
      ?.setValue((tempTotal * 1.07).toFixed(2));
  }

  public setPrice(index: number): void {
    this.ItemList.at(index)?.get('qty')?.setValue(1);
    this.ItemList.at(index)?.get('pricePerPiece')?.setValue(0);
    this.ItemList.at(index)?.get('discount')?.setValue(0);
    this.ItemList.at(index)?.get('amount')?.setValue(0);
  }

  public addChoice(
    controlName: string,
    category: string,
    event: any,
    index: number
  ) {
    const ItemList = this.quotationInfo.get('quotationList') as FormArray;

    if (event.target.value === 'new') {
      var name = prompt('กรุณากรอกข้อมูล', '');
      if (name != null && name != '') {
        this.http
          .post(
            '/api/quotations/itemChoice',
            { withCredentials: false },
            {
              choice: {
                Category: category,
                value: name,
              },
            }
          )
          .subscribe((result) => {
            this.http
              .get('api/quotations/itemChoice')
              .subscribe((itemChoice) => {
                this.generatorService.setItemChoice(itemChoice);
                ItemList.at(index)?.get(controlName)?.setValue(result.insertId);
              });
          });
      } else {
        ItemList.at(index)?.get(controlName)?.setValue(0);
      }
    }
  }
  public changeDiscountType(index: number, value: any): void {
    this.discountSelector[index] = value;
    // this.ItemList?.at(index)?.get('discount')?.setValue(0);
    this.ItemList?.at(index)
      ?.get('amount')
      ?.setValue(this.ItemList?.at(index)?.get('pricePerPiece')?.value);
  }
}
