<button (click)="printPage()">พิมพ์</button>
<button (click)="decreaseImgSize()">ลดขนาดรูป</button>
<button (click)="increaseImgSize()">เพิ่มขนาดรูป</button>
<button (click)="decreaseFontSize()">ลดขนาดฟ้อนต์</button>
<button (click)="increaseFontSize()">เพิ่มขนาดฟ้อนต์</button>
<div
  class="container"
  id="pdfTable"
  #pdfTable=""
  [ngStyle]="{
    'font-size': fontSize + 'px'
  }"
>
  <table class="company-detail-table no-border">
    <tbody class="no-border">
      <tr class="no-border">
        <td class="no-border" style="text-align: right; width: 142px">
          <img
            style="max-height: 100px"
            src="https://storage.googleapis.com/gothic-style-379913.appspot.com/logo.png"
            alt=""
          />
        </td>
        <td class="no-border">
          <div>
            <p class="company-title">บริษัท พีเคซีเกียร์. (ไทยแลนด์) จำกัด</p>
            <p class="company-title">PKC GEAR. (Thailand) CO.,LTD.</p>
            <p class="company-detail">
              135 หมู่ที่ 5 ตำบล/แขวง แครำย อำเภอ/เขต กระทุ่มแบน จังหวัด
              สมุทรสาคร 74110
            </p>
            <p class="company-detail">
              135 Moo 5 Kaerai, Kratumban, Samutsakorn, 74110 Thailand.
            </p>
            <p class="company-detail">
              Tel. 089-776-9964, 083-426-9666 Fax : 0-3411-2518
            </p>
            <p class="company-detail">Email: sales@pkcgearthailand.com</p>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
  <div style="text-align: center">
    <h3>ใบเสนอราคา</h3>
    <h3>(Quotation)</h3>
  </div>
  <table>
    <tbody>
      <tr class="no-border">
        <td class="no-border" colspan="2">Customer</td>
        <td class="no-border">
          {{ quotation.get("customerDetail")?.get("clientName")?.value }}
        </td>
        <td>Date</td>
        <td>
          {{ quotation.get("quotationAutoGen")?.get("date")?.value }}
        </td>
      </tr>
      <tr class="no-border">
        <td colspan="2" class="no-border">Address</td>
        <td class="no-border">
          {{ quotation.get("customerDetail")?.get("address")?.value }}
        </td>
        <td>Quo. No</td>
        <td>
          {{ quotation.get("quotationAutoGen")?.get("quotationNumber")?.value }}
        </td>
      </tr>
      <tr class="no-border">
        <td colspan="2" class="no-border">Contact Person</td>
        <td class="no-border">
          {{ quotation.get("customerDetail")?.get("contactName")?.value }}
        </td>
        <td>Delivery</td>
        <td>
          {{ quotation.get("quotationAutoGen")?.get("delivery")?.value }}
        </td>
      </tr>
      <tr class="no-border">
        <td class="no-border" colspan="2">Email</td>
        <td class="no-border">
          {{ quotation.get("customerDetail")?.get("email")?.value }}
        </td>
        <td>Credit Terms</td>
        <td style="color: red; font-weight: bold">
          {{ quotation.get("quotationAutoGen")?.get("creditTerm")?.value }}
        </td>
      </tr>
      <tr class="no-border">
        <td class="no-border" colspan="2">Tel</td>
        <td class="no-border">
          {{ quotation.get("customerDetail")?.get("telephone")?.value }}
        </td>
        <td></td>
      </tr>
    </tbody>
  </table>
  <table style="border-collapse: collapse">
    <thead>
      <td style="width: 40px;">Item</td>
      <td style="width: 50%;">Description</td>
      <td>QTY</td>
      <td>Unit Price</td>
      <td>Disc.</td>
      <td>Amount</td>
    </thead>
    <tbody>
      <tr
        style="border: 0px; position: relative"
        *ngFor="let control of ItemList.controls; let index = index"
        [ngStyle]="{
          'border-bottom':
            ItemList.at(index).get('itemSet')?.value != 'multi'
              ? '1px solid black'
              : ''
        }"
      >
        <td
          style="
            border: 0px;
            vertical-align: text-top;
            border-right: 1px solid black;
          "
        >
          <p>
            {{ IdArr[index] }}
          </p>
        </td>
        <td style="border: 0px; border-right: 1px solid black">
          <div>
            <p>
              {{
                quotation.get("quotationList")?.at(index)?.get("service").value
                  ? itemChoice.service[
                      quotation.get("quotationList")?.at(index)?.get("service")
                        .value - 1
                    ]["ServiceName"]
                  : ""
              }}
              {{
                quotation.get("quotationList")?.at(index)?.get("type").value
                  ? itemChoice.workType[
                      quotation.get("quotationList")?.at(index)?.get("type")
                        .value - 1
                    ]["WorkTypeName"]
                  : ""
              }}
              {{
                quotation.get("quotationList")?.at(index)?.get("gearNumber")
                  .value
              }}T
              {{
                quotation.get("quotationList")?.at(index)?.get("od").value
                  ? "Ø" +
                    quotation.get("quotationList")?.at(index)?.get("od").value +
                    " x " +
                    quotation.get("quotationList")?.at(index)?.get("length")
                      .value +
                    "mm."
                  : ""
              }}
              <!-- {{quotation.get("quotationList")?.at(index)?.get("cutter")
              .value}} -->
              {{
                quotation.get("quotationList")?.at(index)?.get("cutter").value && (quotation.get("quotationList")?.at(index)?.get("cutter").value!='0')
                  ? itemChoice.cutter[
                      quotation.get("quotationList")?.at(index)?.get("cutter")
                        .value
                    ]["CutterName"]
                  : ""
              }}
              {{
                quotation.get("quotationList")?.at(index)?.get("cutterNo").value
              }}
              {{
                quotation.get("quotationList")?.at(index)?.get("polishing")
                  .value
                  ? replaceAll(
                      quotation
                        .get("quotationList")
                        ?.at(index)
                        ?.get("polishing")
                        .value.toString(),
                      '"',
                      ""
                    )
                  : ""
              }}

              {{
                quotation.get("quotationList")?.at(index)?.get("coating")?.value && quotation.get("quotationList")?.at(index)?.get("coating")?.value < 4
                  ? "ชุบแข็ง"
                  : ""
              }}
              {{
                quotation.get("quotationList")?.at(index)?.get("coating")?.value
                  ? itemChoice.coating[
                      quotation.get("quotationList")?.at(index)?.get("coating")
                        ?.value - 1
                    ]["CoatingName"]
                  : ""
              }}
              {{
                quotation.get("quotationList")?.at(index)?.get("addInfo")?.value
                  ? quotation.get("quotationList")?.at(index)?.get("addInfo")
                      ?.value
                  : ""
              }}
              {{ quotation.get("quotationList")?.at(index)?.get() }}
            </p>
          </div>
          <img
            [ngStyle]="{
              'max-height': maxHeight + 'px'
            }"
            class="attach-img"
            [src]="ItemList.at(index).get('imgUrl')?.value"
            *ngIf="ItemList.at(index)?.get('imgUrl')?.value"
          />
          <p style="font-weight: bold; color: red">
            {{
              quotation.get("quotationList")?.at(index)?.get("material").value
                ? "MAT;" +
                  itemChoice.material[
                    quotation.get("quotationList")?.at(index)?.get("material")
                      .value - 1
                  ].MaterialName
                : ""
            }}

            {{
              quotation.get("quotationList")?.at(index)?.get("matCountry")
                .value != 0 &&
              quotation.get("quotationList")?.at(index)?.get("matCountry").value
                ? "(" +
                  itemChoice.country[
                    quotation.get("quotationList")?.at(index)?.get("matCountry")
                      .value
                  ].CountryName +
                  ")"
                : ""
            }}
          </p>
          <p
            class="remark"
            *ngIf="
              quotation.get('quotationList')?.at(index)?.get('remark').value
            "
          >
            หมายเหตุ**
            {{
              quotation.get("quotationList")?.at(index)?.get("remark").value
            }}**
          </p>
        </td>
        <td style="border: 0px; border-right: 1px solid black;vertical-align:top">
          <p
            *ngIf="
              quotation.get('quotationList')?.at(index)?.get('itemSet')
                ?.value != 'multi'
            "
          >
            {{ quotation.get("quotationList")?.at(index)?.get("qty").value }}
          </p>
        </td>
        <td style="border: 0px; border-right: 1px solid black;vertical-align:top">
          <p
            *ngIf="
              quotation.get('quotationList')?.at(index)?.get('itemSet')
                ?.value != 'multi'
            "
          >
            {{
              quotation.get("quotationList")?.at(index)?.get("pricePerPiece")
                ?.value | currency : " "
            }}
          </p>
        </td>
        <td style="border: 0px; border-right: 1px solid black; vertical-align:top">
          <p
            *ngIf="
              quotation.get('quotationList')?.at(index)?.get('itemSet')
                ?.value != 'multi'
            "
          >
            {{
              quotation.get("quotationList")?.at(index)?.get("discount")
                .value != 0
                ? (
                    (quotation.get("quotationList")?.at(index)?.get("discount")
                      ?.value *
                      quotation
                        .get("quotationList")
                        ?.at(index)
                        ?.get("pricePerPiece")?.value) /
                    100
                  ).toFixed(2)
                : ("" | currency : " ")
            }}
          </p>
        </td>
        <td style="border: 0px; border-right: 1px solid black;vertical-align:top">
          <p
            *ngIf="
              quotation.get('quotationList')?.at(index)?.get('itemSet')
                ?.value != 'multi'
            "
          >
            {{
              quotation.get("quotationList")?.at(index)?.get("amount")?.value
                | currency : " "
            }}
          </p>
        </td>
      </tr>
      <tr>
        <td
          colspan="2"
          class="no-border"
          style="border-top: 1px solid black"
        ></td>
        <td colspan="3">
          <p>ส่วนลด</p>
        </td>
        <td>
          <p>
            {{
              quotation.get("quotationSummary")?.get("discount")?.value
                | currency : " "
            }}
          </p>
        </td>
      </tr>
      <tr>
        <td colspan="2" class="no-border"></td>
        <td colspan="3">
          <p>รวมราคา Total</p>
        </td>
        <td>
          <p>
            {{
              quotation.get("quotationSummary")?.get("total")?.value
                | currency : " "
            }}
          </p>
        </td>
      </tr>
      <tr>
        <td colspan="2" class="no-border"></td>
        <td colspan="3">
          <p>ภาษีมูลค่าเพิ่ม 7%</p>
        </td>
        <td>
          <p>
            {{
              quotation.get("quotationSummary")?.get("vat")?.value
                | currency : " "
            }}
          </p>
        </td>
      </tr>
      <tr>
        <td colspan="2" class="no-border">ทั้งหมด {{ thaiBahtText }}</td>
        <td colspan="3">
          <p>รวมราคาทั้งสิ้น Grand TOTAL</p>
        </td>
        <td>
          <p>
            {{
              quotation.get("quotationSummary")?.get("grandTotal")?.value
                | currency : " "
            }}
          </p>
        </td>
      </tr>
      <tr>
        <td colspan="2">
          <p>
            หมายเหตุ (remark)
            {{ quotation.get("quotationSummary")?.get("remark").value }}
          </p>
        </td>
        <td colspan="3">
          <h4>
            มัดจำ Deposit
            {{ quotation.get("quotationSummary")?.get("deposit").value }}%
          </h4>
        </td>
        <td>
          {{
            (quotation.get("quotationSummary")?.get("grandTotal")?.value *
              quotation.get("quotationSummary")?.get("deposit")?.value) /
              100 | number : "1.0-0"
          }}
        </td>
      </tr>
      <tr>
        <td colspan="2">
          <div
            style="
              margin: 50px 20px 0px 20px;
              border-bottom: 1px solid black;
              text-align: center;
            "
          >
            {{ quotation.get("quotationSummary")?.get("approver").value }}
          </div>
          <div style="text-align: center">ผู้อนุมัติซื้อ</div>
        </td>
        <td colspan="2">
          <div
            style="
              margin: 50px 20px 0px 20px;
              border-bottom: 1px solid black;
              text-align: center;
            "
          >
            {{ quotation.get("quotationSummary")?.get("creator").value }}
          </div>
          <div style="text-align: center">ผู้จัดทำ</div>
        </td>
        <td colspan="2">
          <div
            style="
              margin: 50px 20px 0px 20px;
              border-bottom: 1px solid black;
              text-align: center;
            "
          >
            {{ quotation.get("quotationSummary")?.get("signedBy").value }}
          </div>
          <div style="text-align: center">ผู้มีอำนาจลงนาม</div>
        </td>
      </tr>
    </tbody>
  </table>
  <table></table>
</div>
