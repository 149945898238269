<form [formGroup]="quotationInfo" class="was-validated">
  <div class="form-group" formGroupName="customerDetail">
    <div class="input-group m-2 pt-3">
      <!-- <span class="input-group-text">ชื่อลูกค้า</span> -->

      <mat-form-field appearance="fill">
        <mat-label>ชื่อลูกค้า</mat-label>
        <input
          type="text"
          placeholder="Pick one"
          aria-label="Number"
          matInput
          formControlName="clientName"
          [matAutocomplete]="auto"
        />
        <mat-autocomplete #auto="matAutocomplete">
          <mat-option
            *ngFor="let client of clientlistFiltered | async"
            [value]="client.ClientName"
            (click)="sendClientId(client.ClientId)"
          >
            {{ client.ClientName }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>

      <!-- <input
        formControlName="clientName"
        type="text"
        class="form-control"
        matInput
        aria-label="Username"
        aria-describedby="basic-addon1"
        [matAutocomplete]="auto"
        required
      /> -->
    </div>

    <div class="input-group mb-3">
      <span class="input-group-text">อีเมลล์ติดต่อ</span>

      <input
        formControlName="email"
        type="text"
        class="form-control"
        placeholder="username@example.com"
        aria-label="Recipient's username"
        aria-describedby="basic-addon2"
      />
      <button class="btn btn-danger" (click)="noEmail()">ไม่มีอีเมลล์</button>
    </div>
    <div class="input-group mb-3">
      <span class="input-group-text">ที่อยู่</span>
      <input
        formControlName="address"
        type="text"
        class="form-control"
        aria-label="tel"
        required
      />
    </div>
    <div class="input-group mb-3">
      <span class="input-group-text">ชื่อผู้ติดต่อ</span>
      <input
        formControlName="contactName"
        type="text"
        class="form-control"
        aria-label="tel"
        required
      />
    </div>

    <div class="input-group mb-3">
      <span class="input-group-text">โทร</span>
      <input
        formControlName="telephone"
        type="text"
        class="form-control"
        aria-label="tel"
        required
      />
    </div>
  </div>
</form>
