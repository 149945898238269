export class QuotationForm {
  id: string;
  quotationNumber: string;
  creditTerm: string;
  clientName: string;
  email: string;
  address : string;
  contactName: string;
  telephone: number;
  date: number;
  quotationList: QuotationItem[];
}

export class QuotationItem {
  service: number;
  type: number;
  cutter: number;
  cutterNo: number;
  polishing: number;
  coating: number;
  gearNumber: number;
  amount: number;
  qty: number;
  pricePerPiece: number;
  material: number;
  matCountry: number;
  od: number;
  discount: number;
  remark: string;
  imgUrl: string;
  addInfo: string;
}

export const workType = [
  '',
  'เฟืองตรง',
  'เพลาเฟืองตรง',
  'เฟืองเฉียง',
  'เพลาเฟืองเฉียง',
  'เฟืองสะพาน',
  'ดอกจอกตรง',
  'ดอกจอกเฉียง',
  'เพลาดอกจอกเฉียง',
  'เฟืองเกลียวหนอน',
  'เฟืองเดินเกลียวหนอน',
  'อื่นๆ',
];

export const cutter = ['','เครื่องตัด M.6', 'เครื่องตัด DP'];

export const polishing = ['','เจียร', 'เจียรฟันเฟือง'];

export const coating = ['','Induction','Carburizing'];
