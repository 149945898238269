import { Component, OnInit } from '@angular/core';
import { ApiCallService } from '../api-call.service';
import { GeneratorService } from '../generator/generator.service';

@Component({
  selector: 'app-searcher',
  templateUrl: './searcher.component.html',
  styleUrls: ['./searcher.component.scss'],
})
export class SearcherComponent implements OnInit {
  public itemList: any[] = [];

  constructor(
    private http: ApiCallService,
    private generatorService: GeneratorService
  ) {}

  ngOnInit(): void {
    this.getItemList();
  }

  public getItemList() {
    this.http
      .get('/api/quotations/itemSoldHistory', {
        withCredentials: false,
      })
      .subscribe((data) => {
        this.generatorService.setItemList(data);
        this.generatorService.onItemListChange().subscribe((itemList) => {
          this.itemList = itemList;
        });
      });
  }
}
