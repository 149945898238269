import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import { QuotationItem, QuotationForm } from '../model/model';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class GeneratorService {
  constructor(private fb: FormBuilder) {}

  private quotation: BehaviorSubject<FormGroup> = new BehaviorSubject(
    this.getForm()
  );

  private itemSearch: BehaviorSubject<FormGroup> = new BehaviorSubject(
    this.getItemSearch()
  );

  private itemList: BehaviorSubject<[any]> = new BehaviorSubject([{}]);

  private itemChoice: BehaviorSubject<any> = new BehaviorSubject(
    this.getItemChoice()
  );

  onQuotationChange(): Observable<FormGroup> {
    return this.quotation.asObservable();
  }

  onItemChange(): Observable<FormGroup> {
    return this.itemSearch.asObservable();
  }
  onItemListChange(): Observable<[any]> {
    return this.itemList.asObservable();
  }
  onItemChoiceChange(): Observable<any> {
    return this.itemChoice.asObservable();
  }
  setQuotation(nextQuotation: FormGroup): void {
    this.quotation.next(nextQuotation);
  }

  setItemSearch(nextItemSearch: FormGroup): void {
    this.itemSearch.next(nextItemSearch);
  }
  setItemList(nextItemList: [any]): void {
    this.itemList.next(nextItemList);
  }
  setItemChoice(nextItemChoice: any): void {
    this.itemChoice.next(nextItemChoice);
  }
  resetQuotation(): void {
    this.quotation.next(this.getForm());
  }

  resetItemSearch(): void {
    this.itemSearch.next(this.getItemSearch());
  }
  resetItemList(): void {
    this.itemList.next([{}]);
  }
  resetItemChoice(): void {
    this.itemChoice.next(this.getItemChoice());
  }
  static generateDefaultForm() {
    const quotationInfo: QuotationForm = {
      id: '',
      quotationNumber: '',
      creditTerm: '',
      clientName: '',
      email: '',
      address: '',
      contactName: '',
      telephone: 0,
      date: 0,
      quotationList: [],
    };
  }

  public getForm(): FormGroup {
    let today = new Date();
    return this.fb.group({
      quotationAutoGen: this.fb.group({
        // id: ['', Validators.required],
        quotationNumber: [
          today.getFullYear() + ('0' + (today.getMonth() + 1)).slice(-2),
          Validators.required,
        ],
        creditTerm: ['', Validators.required],
        delivery: ['-', Validators.required],
        date: [today.toLocaleDateString('ta-LK'), Validators.required],
      }),
      customerDetail: this.fb.group({
        clientName: [null, Validators.required],
        email: [null, Validators.required],
        contactName: [null, Validators.required],
        address: [null, Validators.required],
        telephone: [null, Validators.required],
      }),
      quotationList: this.fb.array([this.getQuotationItem()]),
      quotationSummary: this.fb.group({
        remark: [''],
        deposit: [0, Validators.required],
        discount: [0, Validators.required],
        total: [0, Validators.required],
        vat: [0, Validators.required],
        grandTotal: [0, Validators.required],
        approver: null,
        creator: [null, Validators.required],
        signedBy: [null, Validators.required],
      }),
    });
  }

  public getQuotationItem(): FormGroup {
    return this.fb.group({
      service: [null, Validators.required],
      type: [null, Validators.required],
      cutter: [null],
      cutterNo: [null],
      polishing: null,
      coating: [null, Validators.required],
      gearNumber: [null, Validators.required],
      amount: [null, Validators.required],
      qty: [1, Validators.required],
      pricePerPiece: [null, Validators.required],
      material: [null, Validators.required],
      matCountry: [null],
      od: [''],
      length: [''],
      discount: [0, Validators.required],
      discountManual: 0,
      itemSet: ['single', Validators.required],
      remark: [''],
      addInfo: [''],
      imgUrl: [''],
    });
  }

  public getItemSearch(): FormGroup {
    return this.fb.group({
      type: null,
      service: null,
      cutter: null,
      cutterNo: null,
      polishing: null,
      material: null,
      matCountry: null,
      coating: null,
      gearNumberMin: null,
      gearNumberMax: null,
      qty: null,
      pricePerPieceMin: null,
      pricePerPieceMax: null,
      odMin: null,
      odMax: null,
      lengthMin: null,
      lengthMax: null,
      clientName: null,
      quotationNumber:null
    });
  }

  public getItemChoice() {
    return {
      cutter: [{ CutterId: null, CutterName: null }],
      coating: [{ CoatingId: null, CoatingName: null }],
      polishing: [{ PolishingId: null, PolishingName: null }],
      workType: [{ WorkTypeId: null, WorkTypeName: null }],
    };
  }

  public mapDefaultQuotationItem(itemList: any[]) {
    return (itemList || []).map((item) => {
      return this.fb.group({
        service: [item.ServiceId, Validators.required],
        type: [item.TypeId, Validators.required],
        cutter: [item.CutterId],
        cutterNo: [item.CutterNo],
        polishing: [item.PolishingArr.slice(1,-1)],
        coating: [item.CoatingId, Validators.required],
        gearNumber: [item.GearNumber, Validators.required],
        //amount mean pricePerpiece * number of pieces
        amount: [item.price, Validators.required],
        //item.amount mean net price after discount on each item
        qty: [item.amount, Validators.required],
        pricePerPiece: [(item.price*100/(100-item.Discount)) / item.amount, Validators.required],
        material: [item.MaterialId, Validators.required],
        matCountry: item.CountryId,
        od: [item.OD],
        length: [item.Length],
        discount: [item.Discount, Validators.required],
        discountManual: (item.Discount*((item.price*100/(100-item.Discount)) / item.amount))/100,
        itemSet: [item.ItemSet,Validators.required],
        remark: [item.ItemRemark],
        addInfo: [item.AddInfo],
        imgUrl: [item.ImgUrl],
      });
    });
  }
  public mapForm(quotationData: any,quotationList:any[]): FormGroup {
    return this.fb.group({
      quotationAutoGen: this.fb.group({
        quotationNumber: [quotationData.QuotationNumber, Validators.required],
        creditTerm: [quotationData.CreditTerm, Validators.required],
        delivery: [quotationData.Delivery, Validators.required],
        date: [quotationData.quotationDate.slice(0, 10)],
      }),
      customerDetail: this.fb.group({
        clientName: [quotationData.ClientName, Validators.required],
        email: [quotationData.Email, Validators.required],
        contactName: [quotationData.Contact, Validators.required],
        address: [quotationData.Address, Validators.required],
        telephone: [quotationData.Telephone, Validators.required],
      }),
      quotationList: this.fb.array(
        this.mapDefaultQuotationItem(quotationList)
      ),
      quotationSummary: this.fb.group({
        remark: [quotationData.Remark],
        deposit: [quotationData.Deposit.toFixed(2), Validators.required],
        discount: [quotationData.FinalDiscount.toFixed(2), Validators.required],
        total: [quotationData.Total, Validators.required],
        vat: [(quotationData.Total * 0.07).toFixed(2), Validators.required],
        grandTotal: [quotationData.GTotal, Validators.required],
        approver: quotationData.approver,
        creator: [quotationData.Creator, Validators.required],
        signedBy: [quotationData.SignedBy, Validators.required],
      }),
    });
  }
}
