import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
@Injectable()
export class ApiCallService {
  constructor(
    // Angular Modules
    private http: HttpClient
  ) {}
  public get(url: string, options?: any) {
    return this.http.get<any>(url, {
      responseType: 'json'
    });
  }

  public post(url: string, options?: any, body?:any) {
    return this.http.post<any>(url,body, {
      responseType: 'json'
    });
  }
 
}