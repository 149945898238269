<table class="table table-bordered">
  <tr>
    <td colspan="2">ชื่อลูกค้า: {{ item.ClientName }}</td>
    <td colspan="2">เลขที่ใบเสนอราคา: {{ item.QuotationNumber }}</td>
  </tr>
  <tr>
    <td>
      ประเภทงาน
      {{
        itemChoice.workType[item.TypeId - 1]?.WorkTypeName
          ? itemChoice.workType[item.TypeId - 1].WorkTypeName
          : ""
      }}
    </td>
    <td>
      service
      {{
        itemChoice.service[item.ServiceId - 1]?.ServiceName
          ? itemChoice.service[item.ServiceId - 1]?.ServiceName
          : ""
      }}
    </td>
    <td colspan="2">วันที่ {{ item.quotationDate.slice(0, 10) }}</td>
  </tr>
  <tr>
    <td>
      เครื่องตัด
      {{
        itemChoice.cutter[item.CutterId - 1]?.CutterName
          ? itemChoice.cutter[item.CutterId - 1].CutterName + item.CutterNo
          : ""
      }}
    </td>
    <td colspan="2">
      {{ item.PolishingArr ? item.PolishingArr : "" }}
    </td>
    <td>จำนวนฟัน {{ item.GearNumber }}</td>
  </tr>
  <tr>
    <td>
      วัสดุ
      {{
        itemChoice.material[item.MaterialId - 1]?.MaterialName
          ? itemChoice.material[item.MaterialId - 1]?.MaterialName
          : ""
      }}
      {{
        itemChoice.country[item.CountryId - 1]?.CountryName
          ? itemChoice.country[item.CountryId - 1]?.CountryName
          : ""
      }}
    </td>

    <td>OD {{ item.OD }} * {{ item.Length }}</td>
    <td>
      ชุบ (Heat treatment)
      {{
        itemChoice.coating[item.CoatingId - 1]?.CoatingName
          ? itemChoice.coating[item.CoatingId - 1].CoatingName
          : ""
      }}
    </td>
  </tr>
  <tr>
    <td>หมายเหตุ {{ item.ItemRemark }}</td>
    <td>จำนวนชิ้น {{ item.amount }}</td>
    <td colspan="2">ราคารวม {{ item.price | currency : " " }} บาท</td>
  </tr>
  <tr>
    <td colspan="4">รายละเอียดเพิ่มเติม {{ item.AddInfo }}</td>
  </tr>
  <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
    <img [id]="'img-' + item.ItemId" class="attach-img" [src]="" />
  </div>
</table>
<button
  type="button"
  class="btn btn-outline-primary m-2"
  (click)="showPicture(item.ImgUrl, item.ItemId)"
  [attr.aria-expanded]="!isCollapsed"
  aria-controls="collapseExample"
>
  แสดงรูปภาพ
</button>
<button class="btn btn-info" (click)="showQuotation(item.QuotationId)">
  แสดงใบเสนอราคา
</button>
<button
  type="button"
  class="btn btn-outline-primary m-2"
  *ngIf="!item.QuotationNumber?.includes('-r')"
  (click)="getItemList()"
>
  แสดงรายrevise
</button>
<div *ngIf="isRevCollapsed">
  <div
    style="background-color: beige"
    *ngFor="let itemRev of itemList; let index = index"
  >
    <app-result-list
      *ngIf="itemRev.QuotationNumber?.includes(item.QuotationNumber + '-r')"
      [item]="itemRev"
    ></app-result-list>
  </div>
</div>
