import { Component, Input, OnInit } from '@angular/core';
import { GeneratorService } from '../../generator/generator.service';
import { FormGroup } from '@angular/forms';
import { ApiCallService } from '../../api-call.service';

@Component({
  selector: 'app-search-panel',
  templateUrl: './search-panel.component.html',
  styleUrls: ['./search-panel.component.scss'],
})
export class SearchPanelComponent implements OnInit {
  // @Input() item: any;

  public itemSearchForm: FormGroup;
  public isCollapsed = false;
  public itemChoice: any;


  constructor(
    private http: ApiCallService,
    public generatorService: GeneratorService
  ) {}

  ngOnInit(): void {
    this.generatorService.resetItemSearch();
    this.generatorService.onItemChange().subscribe((itemSearchForm) => {
      this.itemSearchForm = itemSearchForm;
    });
    this.generatorService.onItemChoiceChange().subscribe((itemChoice) => {
      this.itemChoice = itemChoice;
    });
  }

  public searchItem(): void {
    let body = { filter: this.itemSearchForm.getRawValue() };
    this.http
      .post(
        '/api/quotations/itemSoldHistoryfiltered',
        { withCredentials: false },
        body
      )
      .subscribe((filteredItemList) => {
        this.generatorService.setItemList(filteredItemList);
      });
  }

  public addPolishing(): void {
    this.itemSearchForm.get('polishing')?.setValue([]);
    let tempPolishArr: any[] = [];
    this.itemChoice.polishing.forEach(
      (element: { PolishingId: number; PolishingName: any }) => {
        var cb = <HTMLInputElement>(
          document.getElementById(
            'polishing-' + element.PolishingId
          )
        );

        if (cb.checked) {
          tempPolishArr.push(element.PolishingName);
        }
      }
    );
    this.itemSearchForm.get('polishing')?.setValue(tempPolishArr);
  }
}
