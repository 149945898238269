<form [formGroup]="quotationInfo" class="was-validated">
  <div formGroupName="quotationAutoGen">
    <div class="input-group mb-3 pt-3">
      <span class="input-group-text">วันที่</span>
      <input
        formControlName="date"
        type="text"
        class="form-control"
        placeholder="date"
        aria-label="date"
        aria-describedby="basic-addon1"
      />
    </div>
    <div class="input-group mb-3">
      <span class="input-group-text">เลขที่ใบเสนอราคา</span>
      <input
        formControlName="quotationNumber"
        type="text"
        class="form-control"
        aria-label="quotationNumber"
        required
      />
    </div>

    <div class="input-group mb-3">
      <div class="input-group-prepend">
        <label class="input-group-text" for="creditTerm">Credit term</label>
      </div>
      <select
        formControlName="creditTerm"
        class="form-select form-select-sm"
        id="creditTerm"
        (change)="setDeposit($event)"
        required
      >
        <option value="Credit">Credit</option>
        <option value ="Cash">Cash</option>
        <option value = "มัดจำ 50% เมื่อตกลง / ส่วนที่เหลือในวันรับของ">มัดจำ 50% เมื่อตกลง / ส่วนที่เหลือในวันรับของ</option>
      </select>
    </div>
   
    <div class="input-group mb-3">
      <span class="input-group-text">delivery</span>
      <input
        formControlName="delivery"
        type="text"
        class="form-control"
        aria-label="delivery"
        required
      />
    </div>
  </div>
</form>
