import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { GeneratorService } from '../generator.service';


declare function ArabicNumberToText(Number: string): any;
declare function CheckNumber(Number: string): any;
@Component({
  selector: 'app-quotation-summary',
  templateUrl: './quotation-summary.component.html',
  styleUrls: ['./quotation-summary.component.scss'],
})
export class QuotationSummaryComponent implements OnInit {
  @Input() quotationInfo: FormGroup;

  public remarkOption: boolean = true;
  public thaiBahtText = '';

  constructor(
    private generatorService : GeneratorService
  ) {}

  ngOnInit(): void {
    if(this.quotationInfo.get('quotationSummary')?.get('remark')?.value != ''){
      this.remarkOption = true;
      let remarkSelector = <HTMLSelectElement>(document.getElementById('remarkSelector'));
      remarkSelector.value = '1'
    }
    this.generatorService.onQuotationChange().subscribe((quotation) => {
      this.quotationInfo = quotation;

      let tempTotal: number = this.quotationInfo
        ?.get('quotationList')
        ?.value.reduce(
          (acc: any, cur: any) => parseFloat(acc) + parseFloat(cur.amount),
          0
        );
      const discount = this.quotationInfo
        ?.get('quotationSummary')
        ?.get('discount')?.value;

      tempTotal = tempTotal - discount
      this.quotationInfo
        ?.get('quotationSummary')
        ?.get('total')
        ?.setValue(tempTotal.toFixed(2));
      this.quotationInfo
        ?.get('quotationSummary')
        ?.get('vat')
        ?.setValue((tempTotal * 0.07).toFixed(2));
      this.quotationInfo
        ?.get('quotationSummary')
        ?.get('grandTotal')
        ?.setValue((tempTotal * 1.07).toFixed(2));
      this.thaiBahtText = ArabicNumberToText(
        this.quotationInfo?.get('quotationSummary')?.get('grandTotal')?.value
      );
    });
    this.generatorService.setQuotation(this.quotationInfo);
  }

  public assignRemark(event: any): void {
    if (event.target.value != 1) {
      this.quotationInfo.get('quotationSummary')?.get('remark')?.setValue('');
      this.remarkOption = true;
    } else {
      this.remarkOption = false;
      console.log(this.quotationInfo);
      this.quotationInfo
        .get('quotationSummary')
        ?.get('remark')
        ?.setValue(
          'โอนชำระมัดจำเข้าบัญชีบริษัท เลขที่ 0251097135 ธนาคารกสิกรไทย ชื่อบัญชี บจก.พีเคซี เกียร์.(ไทยแลนด์) จำกัด'
        );
    }
  }
}
