import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable, map, startWith } from 'rxjs';
import { ApiCallService } from 'src/app/api-call.service';

@Component({
  selector: 'app-customer-detail',
  templateUrl: './customer-detail.component.html',
  styleUrls: ['./customer-detail.component.scss'],
})
export class CustomerDetailComponent implements OnInit {
  @Input() quotationInfo: FormGroup;

  public clientlist = [
    { clientId: 1, ClientName: 'test1' },
    { clientId: 1, ClientName: 'dest1' },
    { clientId: 1, ClientName: 'sest1' },
  ];
  public clientlistFiltered: Observable<any[]> | undefined;

  constructor(private http: ApiCallService) {}

  ngOnInit(): void {
    this.getClientNameList();
  }

  private getClientNameList() {
    this.http
      .get('/api/quotations/client', { withCredentials: false })
      .subscribe((clientList) => {
        this.clientlist = clientList;
        this.clientlistFiltered = this.quotationInfo
          ?.get('customerDetail')
          ?.get('clientName')
          ?.valueChanges.pipe(
            startWith(''),
            map((value) => this._filter(value || ''))
          );
      });
  }

  private _filter(value: string): any[] {
    return this.clientlist.filter((client) =>
      client.ClientName.toLowerCase().includes(value.toLocaleLowerCase())
    );
  }

  public sendClientId(id: number): void {
    this.http
      .post(
        'api/quotations/clientId',
        { withCredentials: false },
        { client: { ClientId: id } }
      )
      .subscribe((clientData) => {
        this.quotationInfo?.get('customerDetail')?.get('email')?.setValue(clientData[0].Email);
        this.quotationInfo?.get('customerDetail')?.get('address')?.setValue(clientData[0].Address);
        this.quotationInfo?.get('customerDetail')?.get('contactName')?.setValue(clientData[0].Contact);
        this.quotationInfo?.get('customerDetail')?.get('telephone')?.setValue(clientData[0].Telephone);
      });
  }

  public noEmail(): void {
    this.quotationInfo
      .get('customerDetail')
      ?.get('email')
      ?.setValue('no email');
  }
}
