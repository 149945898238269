<div class="container">
  <div class="row">
    <div class="col-sm-4  sidebar">
      <div class="sidebar">
        <app-search-panel ></app-search-panel>
      </div>
    </div>
    <div class="col-sm-8 pt-3">
      <!-- ผลการค้นหา: {{itemList.length}} -->
      <div *ngFor="let item of itemList; let index = index">
        <app-result-list *ngIf="!item.QuotationNumber?.includes('rev') || !item.QuotationNumber" [item]="itemList[index]"></app-result-list>
      </div>
    </div>
  </div>
</div>
