<div class="fixed-top top-bar d-block">
  <div class="container">
    <button
      type="button"
      class="btn m-1"
      [routerLink]="['/create']"
      routerLinkActive="active"
    >
      สร้างใบเสนอราคา
    </button>
    <button
      type="button"
      class="btn m-1"
      [routerLink]="['/search']"
      routerLinkActive="active"
    >
      ค้นหาประวัติข้อมูลสินค้า
    </button>
  </div>
</div>
<div class="container d-block">
  <div class="container">
    <div class="row">
      <div class="mt-4 col-sm-6">.</div>
    <div class="mt-4 col-sm-6">.</div>
    </div>
  </div>
  <router-outlet> </router-outlet>
</div>
