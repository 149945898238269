import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { GeneratorComponent } from './generator/generator.component';
import { SearcherComponent } from './searcher/searcher.component';
import { AppComponent } from './app.component';
import { PreviewQuotationComponent } from './generator/preview-quotation/preview-quotation.component';

const routes: Routes = [
  // { path: '', redirectTo: 'home', pathMatch: 'full' },
  // { path: 'home', component: AppComponent },
  { path: 'search', component: SearcherComponent },
  { path: 'create', component: GeneratorComponent },
  { path: 'preview', component: PreviewQuotationComponent },
  // { path: '**', component: AppComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
