import { Component, OnDestroy, OnInit } from '@angular/core';
import { GeneratorService } from '../generator.service';
import { FormArray } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ApiCallService } from '../../api-call.service';

declare function ArabicNumberToText(Number: string): any;
declare function CheckNumber(Number: string): any;

@Component({
  selector: 'app-preview-quotation',
  templateUrl: './preview-quotation.component.html',
  styleUrls: ['./preview-quotation.component.scss'],
})
export class PreviewQuotationComponent implements OnInit, OnDestroy {
  public quotation: any;
  public thaiBahtText = '';
  public itemChoice: any;
  public IdArr: (number | null)[];
  public maxHeight = 300;
  public fontSize = 12;

  constructor(
    private http: ApiCallService,
    private route: ActivatedRoute,
    private generatorService: GeneratorService
  ) {}

  ngOnInit(): void {
    this.generatorService.onItemChoiceChange().subscribe((itemChoice) => {
      this.itemChoice = itemChoice;
      console.log(itemChoice)
    });

    let quotationNo = this.route.snapshot.paramMap.get('quotationNo');
    if (quotationNo) {
      this.http
        .get('/api/quotations/' + quotationNo, { withCredentials: false })
        .subscribe((data: any) => {
          this.generatorService.setQuotation(
            this.generatorService.mapForm(data[0], data)
          );
        });
    }

    this.generatorService.onQuotationChange().subscribe((quotation) => {
      this.quotation = quotation;
      let tempTotal: number = this.quotation
        ?.get('quotationList')
        ?.value.reduce(
          (acc: any, cur: any) => parseFloat(acc) + parseFloat(cur.amount),
          0
        );
      const discount = this.quotation
        ?.get('quotationSummary')
        ?.get('discount')?.value;

      tempTotal = tempTotal - discount;
      this.quotation
        ?.get('quotationSummary')
        ?.get('total')
        ?.setValue(tempTotal.toFixed(2));
      this.quotation
        ?.get('quotationSummary')
        ?.get('vat')
        ?.setValue((tempTotal * 0.07).toFixed(2));
      this.quotation
        ?.get('quotationSummary')
        ?.get('grandTotal')
        ?.setValue((tempTotal * 1.07).toFixed(2));
      this.thaiBahtText = ArabicNumberToText(
        this.quotation?.get('quotationSummary')?.get('grandTotal')?.value
      );
      this.IdArr = this.generateTempId();
    });
    this.generatorService.setQuotation(this.quotation);
    console.log(this.quotation.getRawValue())
  }

  get ItemList() {
    return this.quotation?.get('quotationList') as FormArray;
  }

  private generateTempId(): (number | null)[] {
    let tempIdArr: (number | null)[] = [];
    let startId = 1;
    let multiStart = true;
    this.ItemList?.getRawValue().forEach((item, index: number) => {
      if (item.itemSet == 'multiFinal') {
        multiStart = true;
        tempIdArr.push(null);
      } else if (item.itemSet != 'single') {
        tempIdArr.push(startId);
        startId += 1;
      } 
      else if (item.itemSet == 'single') {
        tempIdArr.push(startId);
        startId += 1;
      }else {
        if (multiStart === true) {
          tempIdArr.push(startId);
          startId += 1;
          multiStart = false;
        } else {
          tempIdArr.push(null);
        }
      }
    });
    return tempIdArr;
  }

  public printPage(): void {
    let printContents, popupWin;
    printContents = document.getElementById('pdfTable')?.innerHTML;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    if (popupWin) {
      popupWin.document.open();
      popupWin.document.write(`
				<html>
					<head>
						<style type="text/css">
              #pdfTable { 
                font-size : ${this.fontSize}px;
              }

              table, th, td {
                border: 1px solid black;
                border-collapse: collapse;
                font-size : ${this.fontSize}px;
              }
              table {
                width: 100%;
              }
              p {
                font-family: "Times New Roman";
              }

              .padding-main-divcls{
                padding: 5px;
              }

              .text-center{
                text-align: center
              }
              .width-full{
                width: 100%;
              }
              .attach-img {
                max-width: 400px;
                max-height: ${this.maxHeight}px;
              }
              .no-border {
                border: 0px;
              }
              .remark {
                  font-weight: bold;
              }
              .company-detail{
                font-size: ${this.fontSize-5}px;
              }
              .company-title{
                font-weight: bold;
                font-size: ${this.fontSize-2}px;
              }
              
            </style>

            <body onload="window.print();window.close()">${printContents}</body>
          </head>
        </html>
      `);
      popupWin.document.close();
    }
  }

  private escapeRegExp(text:string) {
    return text.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
  }
  
  public replaceAll(str:string, find:string, replace:string) {
    let tempStr = str.replace(new RegExp(this.escapeRegExp('null'), 'g'), replace);
    return tempStr.replace(new RegExp(this.escapeRegExp(find), 'g'), replace);
  }

  public decreaseImgSize():void{
    this.maxHeight -= 50
  }
  public increaseImgSize():void{
    this.maxHeight += 50
  }

  public decreaseFontSize():void{
    this.fontSize -= 1
  }
  public increaseFontSize():void{
    this.fontSize += 1
  }

  ngOnDestroy(): void {}
}
