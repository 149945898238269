<form [formGroup]="quotationInfo">
  <div formGroupName="quotationSummary">
    <div class="input-group mb-3">
      <span class="input-group-text">หมายเหตุ</span>
      <select id ="remarkSelector" class="form-select form-select-sm" (change)="assignRemark($event)" required>
        <option value='0'></option>
        <option value='1'>
          โอนชำระมัดจำเข้าบัญชีบริษัท เลขที่ 0251097135 ธนาคารกสิกรไทย ชื่อบัญชี
          บจก.พีเคซี เกียร์.(ไทยแลนด์) จำกัด
        </option>
        <option value='2'>อื่นๆ</option>
      </select>
    </div>
    <div class="input-group mb-3" *ngIf="remarkOption">
      <span class="input-group-text">หมายเหตุ</span>
      <input
        formControlName="remark"
        type="text"
        class="form-control"
        placeholder="หมายเหตุ"
        aria-label="remark"
        aria-describedby="basic-addon1"
      />
    </div>
    
    <div class="input-group mb-3" >
      <span class="input-group-text">Deposit</span>
      <input
        formControlName="deposit"
        type="number"
        class="form-control"
        placeholder="มัดจำ%"
        aria-label="deposit"
        aria-describedby="basic-addon1"
        required
      />
    </div>
    <div class="input-group mb-3">
      <span class="input-group-text">ส่วนลด</span>
      <input
        formControlName="discount"
        type="number"
        class="form-control"
        placeholder="ส่วนลด"
        aria-label="discount"
        required
      />
    </div>
    <div class="input-group mb-3">
      <span class="input-group-text">รวมราคา</span>
      <input
        formControlName="total"
        type="number"
        class="form-control"
        placeholder="รวมราคา"
        aria-label="total"
        aria-describedby="basic-addon2"
        required
      />
    </div>

    <div class="input-group mb-3">
      <span class="input-group-text">ภาษีมูลค่าเพิ่ม 7%</span>
      <input
        formControlName="vat"
        type="number"
        class="form-control"
        aria-label="tel"
      />
    </div>
    <div class="input-group mb-3">
      <span class="input-group-text">รวมราคาทั้งสิ้น</span>
      <input
        formControlName="grandTotal"
        type="number"
        class="form-control"
        placeholder="รวมราคาทั้งสิ้น"
        aria-label="grandtotal"
        aria-describedby="basic-addon2"
      />
    </div>
    <div class="input-group mb-3">
      <span class="input-group-text">ผู้อนุมัติ</span>
      <input
        formControlName="approver"
        type="text"
        class="form-control"
        aria-label="approver"
        aria-describedby="basic-addon2"
      />
    </div>
    <div class="input-group mb-3">
      <span class="input-group-text">ผู้จัดทำ</span>
      <input
        formControlName="creator"
        type="text"
        class="form-control"
        aria-label="creator"
        aria-describedby="basic-addon2"
      />
    </div>
    <div class="input-group mb-3">
      <span class="input-group-text">ผู้มีอำนาจลงนาม</span>
      <input
        formControlName="signedBy"
        type="text"
        class="form-control"
        aria-label="signedBy"
        aria-describedby="basic-addon2"
      />
    </div>
  </div>
</form>
