<form [formGroup]="quotationForm">
  <!-- <table class="table"> -->
  <!-- <tr> -->
  <div class="row">
    <div class="col-md-6">
      <app-customer-detail
        [quotationInfo]="quotationForm"
      ></app-customer-detail>
    </div>
    <div class="col-md-6">
      <app-quotation-auto-generate
        [quotationInfo]="quotationForm"
      ></app-quotation-auto-generate>
    </div>
  </div>

  <!-- </tr>
    <tr> -->
  <app-quotation-list [quotationInfo]="quotationForm"></app-quotation-list>
  <!-- </tr>
    <tr> -->
  <app-quotation-summary
    [quotationInfo]="quotationForm"
  ></app-quotation-summary>
  <!-- </tr> -->
  <td>
    <button (click)="previewQuotation()" type="button" class="btn btn-secondary">
      จำลองเอกสาร
    </button>
  </td>
  <td>
    <button (click)="uploadQuotation()" type="button" class="btn btn-primary">
      สร้างและบันทึกเอกสาร
    </button>
  </td>
  <!-- </table> -->
</form>
